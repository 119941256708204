// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-stardust.jpg'; // Replace with the actual path to your logo image

const Header = () => {
  return (
    <header style={styles.header}>
      <Link to="/">
        <img src={logo} alt="Logo" style={styles.logo} />
      </Link>
      {/* Add additional navigation or other header content here */}
    </header>
  );
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#fff', // Change this to your desired background color
  },
  logo: {
    height: '50px', // Adjust the height as needed
    width: 'auto',
  },
};

export default Header;