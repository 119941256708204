import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductList from './components/ProductList'; // Adjust the path if necessary
import ProductDetail from './components/ProductDetail'; // We'll create this component
import Header from './components/Header'; // Import the Header component
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">

                    <Header /> {/* Include the Header component */}
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<ProductList />} />
                        <Route path="/product/:id" element={<ProductDetail />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
